import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Embrace being stuck`}</h1>
    <p>{`As I mentioned in my post about being in `}<a parentName="p" {...{
        "href": "https://theflying.dev/digital-garden/tutorial-hell"
      }}>{`Tutorial Hell`}</a>{` - being stuck does suck. It makes you feel powerless, dumb and makes you start questioning yourself if coding is something you should do.`}</p>
    <p>{`I'll go further and say that being stuck will make you want to pull your hair out, punch your screen and go back to that crappy job you once had when you were younger and were getting little to no money.`}</p>
    <p>{`But as I mentioned in that previous post, being stuck is a very important thing for your growth and you shouldn't fear it.`}</p>
    <h2>{`Why we get stuck`}</h2>
    <p>{`There are many different reasons why you get stuck while coding. Some of these reasons can be because you:`}</p>
    <ul>
      <li parentName="ul">{`didn't understand the problem properly,`}</li>
      <li parentName="ul">{`didn't break the problem into the smallest bits possible,`}</li>
      <li parentName="ul">{`are just tired and need a break,`}</li>
      <li parentName="ul">{`haven't encountered this particular problem yet,`}</li>
      <li parentName="ul">{`don't have enough experience yet`}</li>
    </ul>
    <p>{`One thing that I hear all the time from the `}<a parentName="p" {...{
        "href": "https://completedeveloperpodcast.com"
      }}>{`Complete Developer Podcast`}</a>{` is how Will and BJ sometimes get stuck and they talk with each other about the problem that they are facing. Sometimes one of them has some idea of how to solve the problem because they had faced it in the past.`}</p>
    <h2>{`Embrace the suck`}</h2>
    <p>{`When you do get stuck in a problem, you just need to embrace the suck - to accept that something is extremely unpleasant but unavoidable. `}</p>
    <p>{`There will be times when you get stuck. That's part of being a developer.
Do your research and try to view the problem from a different perspective. Take a break, do something different and then come back to the problem, sometimes your brain just needs that bit of extra time to work out how to fix that particular problem.`}</p>
    <p>{`There is a reason why a lot of times, you go to sleep and the next day you find the solution for said problem.`}</p>
    <p>{`You will also grow a lot after being stuck, you probably tried a lot of different things, did a lot of research and talked with a few people that could offer you guidance. This is a great learning experience.`}</p>
    <h2>{`What can help to get you unstuck`}</h2>
    <p>{`I've been stuck on problems all the time.`}</p>
    <p>{`Sometimes I'm implementing a feature in `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid"
      }}>{`opsdroid`}</a>{` and test it out with the bot running, everything works fine, but when I go and write tests for it I get stuck and have no idea how to write the test for it.`}</p>
    <p>{`I've noticed that most of the times when this happens, the reason is that I need to refactor the code into smaller chunks. `}</p>
    <p>{`So don't be afraid to delete code that you have spent hours working on. `}</p>
    <p>{`Search online if someone had encountered that problem before and try those solutions. There is a reason why `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/"
      }}>{`stackoverflow`}</a>{` is so popular.`}</p>
    <p>{`Check out what people suggest and try to come up with your own version of that code.`}</p>
    <p>{`Finally, ask for help if you are still stuck.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/opsdroid"
      }}>{`Opsdroid`}</a>{` was the first open source project that I contributed to, when I started I didn't have enough experience with Python and there was a lot of things I didn't understand. `}</p>
    <p>{`Also, I couldn't read other people's code so I got stuck all the time because I was working on things that required a better knowledge of the language. Still, I got stuck and worked on it and definitely have learned and grown a lot from the experience.`}</p>
    <p>{`In conclusion, being stuck does suck, but it's part of your growth so embrace it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      